import { apiv1 } from "@/request/apiv1";
import qs from "qs";

// 获取客户的分页列表
export function GetCommentsList(params = { page: 0, size: 20 }) {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/comments/page?${qs.stringify(params, {
      arrayFormat: "repeat"
    })}`)
      .then(res => {
        if (res.status === 200 || res.status === 206) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

// 新增点评
export function PostComment(params = {}) {
  return new Promise((resolve, reject) => {
    apiv1.post(`/clients/follow_ups/comments`, params)
      .then(res => {
        if (res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

// 根据id获取具体的点评
export function GetCommentById(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.get(`/clients/follow_ups/comments/${id}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

// 获取所有的部门数据树形列表
export function GetCommentsTreeList(id = "", params={}) {
  return new Promise((resolve, reject) => {
    let path = "/clients/follow_ups/comments/tree"
    if (id !== "") {
      path = `/clients/follow_ups/comments/tree/${id}`
    }
    path = `${path}?${qs.stringify(params, {
      arrayFormat: "repeat"
    })}`
    apiv1.get(path)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}