<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8"
          ><el-form-item label="客户">
            <BasicSelect
              multiple
              v-model="tableFilters.clientId"
              :allOptions="_clientOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="跟进类型">
            <BasicSelect
              v-model="tableFilters.statusId"
              :allOptions="followUpStatusOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="客户阶段(多选)">
            <BasicSelect
              multiple
              v-model="tableFilters.clientStageId"
              :allOptions="_stageOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="跟进内容">
            <el-input
              v-model="tableFilters.content"
              placeholder="请输入内容"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="创建日期">
            <el-date-picker
              v-model="tableFilters.createdAt"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="最后修改日期">
            <el-date-picker
              v-model="tableFilters.lastModifiedAt"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="创建人">
            <BasicSelect
              v-model="tableFilters.createdById"
              :allOptions="userOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8" v-if="showMoreFilter">
          <el-form-item label="最后修改人">
            <BasicSelect
              v-model="tableFilters.lastModifiedById"
              :allOptions="userOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import formSelection from "../mixins/formSelection";
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
export default {
  components: {
    BasicSelect,
  },
  mixins: [formSelection],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        content: "",
        createdAt: null,
        lastModifiedAt: null,
        statusId: null,
        clientStageId: null,
        createdById: null,
        lastModifiedById: null,
        clientId: [],
      },
    };
  },
  computed: {
    _stageOptions() {
      return this.$store.state.basicOption.stageOptions;
    },
    _clientOptions() {
      return this.$store.state.basicOption.clientOptions;
    },
  },
  created() {
    this.initFormOptions();
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
      this.$emit("confirm", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
      this.$emit("clear", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>