<template>
  <div class="comments-editor-wrapper">
    <el-input
      type="textarea"
      :placeholder="placeholder"
      v-model="form.content"
      :autosize="{
        minRows: 3,
        maxRows: 6,
      }"
      maxlength="1000"
      show-word-limit
    ></el-input>
    <div class="comment-btn-wrapper">
      <el-button type="danger" size="mini" @click="onCancel"
        >取消</el-button
      >
      <el-button type="primary" size="mini" @click="submitCommet"
        >发送</el-button
      >
    </div>
  </div>
</template>

<script>
import { PostComment } from "../api/comment";
import { ShowApiError } from "@/request/error";
export default {
  name: "CommentEditor",
  props: {
    followUpId: {
      type: [Number, String],
      default: null,
    },
    parentId: {
      type: [Number],
      default: null,
    },
    placeholder:{
      type: String,
      default: '请输入'
    },
  },
  data() {
    return {
      submitting: false,
      form: {
        content: "",
        followUpId: null,
        parentId: null,
      },
    };
  },
  watch: {
    followUpId: {
      handler() {
        if (this.followUpId !== null) {
          this.form.followUpId = this.followUpId;
        } else {
          this.form.followUpId = null;
        }
      },
      immediate: true,
    },
    parentId: {
      handler() {
        if (this.parentId !== null) {
          this.form.parentId = this.parentId;
        } else {
          this.form.parentId = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    submitCommet() {
      if(this.form.content === ""){
        this.$message.error("发表内容不能为空!")
        return false
      }
      if(this.form.followUpId === null){
        this.$message.error("对应的跟进记录的ID不能为空!")
        return false
      }
      this.submitting = true;
      PostComment(this.form)
        .then((res) => {
          this.initForm();
          this.$message.success("发布成功!");
          this.$emit("success", res);
        })
        .catch(err => {
          ShowApiError("发表点评失败", err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    initForm() {
      this.form = {
        content: "",
        followUpId: this.followUpId ? this.followUpId : null,
        parentId: this.parentId ? this.parentId : null,
      };
    },
    onCancel(){
      this.initForm()
      this.$emit('cancel')
    },
  },
};
</script>

<style lang="scss" scoped>
.comments-editor-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  .comment-btn-wrapper {
    box-sizing: border-box;
    width: 100%;
    text-align: right;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
  }
}
</style>