<template>
  <section class="base-page">
    <PageHeader
      class="base-page-header base-shadow"
      :desc="`已加载 ${tableData.length} 条，共 ${total} 条`"
    >
      <template #right>
        <el-button
          type="primary"
          class="float-r"
          size="small"
          @click="addRow"
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          >新增跟进记录</el-button
        >
        <el-button class="float-r mt-r" size="small" @click="handleShowMessage"
          ><i class="el-icon-bell"></i> 通知</el-button
        >
      </template>
    </PageHeader>
    <section class="main-list-wrap">
      <section class="list-container" id="listScrollWrapper">
        <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
        <transition-group name="list-complete" tag="ul" class="work-log-list">
          <li
            class="list-item"
            v-for="(item, index) in tableData"
            :key="'worklog' + index"
          >
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <p class="creater-name">
                  <i
                    class="el-icon-star-off"
                    style="cursor: pointer; font-size: 24px"
                    v-if="!item.star"
                    @click="handleStart(item, index)"
                  ></i>
                  <i
                    class="el-icon-star-on text-start"
                    style="cursor: pointer; font-size: 24px"
                    v-if="item.star"
                    @click="handleUnStart(item, index)"
                  ></i>
                  <span
                    @click="onShowClientDetail(item)"
                    title="点击查看客户详情"
                    class="title-nav-hover"
                    >{{ item.clientName }}</span
                  >
                  <el-tag effect="plain" style="margin-left: 10px">{{
                    item.clientStageName
                  }}</el-tag>
                </p>
                <p class="log-push-dete">
                  {{ item.createdByName }} 发布于
                  {{ item.createdAt | date_time }}
                </p>
                <p class="log-push-dete">
                  下次跟进时间：
                  {{
                    item.clientNextFollowUpDate
                      ? item.clientNextFollowUpDate
                      : "暂无"
                  }}
                </p>
              </div>
              <main class="log-content-wrap">
                <div class="flex-box">
                  <div class="left">
                    <!-- 当前的日志内容 -->
                    <div class="log-content-block">
                      <p
                        class="log-content-title"
                        title="点击查看客户详情"
                        @click="onShowClientDetail(item)"
                      >
                        <el-tag
                          class="title-nav-hover"
                          :type="tagTypes[item.followUpStatusId]"
                          >{{ item.followUpStatusName }}</el-tag
                        >
                      </p>
                      <div class="follow-up-content">
                        <span class="content-lable">跟进内容：</span>
                        <div class="log-content-text">
                          <el-input
                            type="textarea"
                            autosize
                            readonly
                            resize="none"
                            v-model="item.content"
                          >
                          </el-input>
                        </div>
                      </div>
                      <div
                        class="follow-up-content"
                        v-if="item.files.length > 0"
                      >
                        <span class="content-lable">附件：</span>
                        <div class="log-content-text">
                          <template v-for="item in item.files">
                            <FilesItem
                              :item="item"
                              :showFileName="false"
                              :showFileDesc="false"
                              :showCheckBox="false"
                              :key="item.id"
                              class="mt-r"
                            />
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <footer class="log-item-footer clearfix">
                <div class="float-l">
                  <el-button
                    type="primary"
                    plain
                    @click="onShowClientDetail(item)"
                    size="small"
                    round
                    >客户详情</el-button
                  >
                  <el-button
                    plain
                    @click="handleMessageAdd(item)"
                    size="small"
                    round
                    >写提醒</el-button
                  >
                </div>
                <div class="float-r">
                  <span class="text-info"
                    >{{ item.lastModifiedByName }} 更新于
                    {{ item.lastModifiedAt | date_time }}
                  </span>
                </div>
              </footer>
            </el-card>
            <div
              class="item-comments-wrap"
              v-if="
                checkPermission([
                  'CLIENT_ADMIN',
                  'CLIENT_GROUP_ADMIN',
                  'CLIENT_USER',
                  'CLIENT_OBSERVER',
                ])
              "
            >
              <Comments :followUpId="item.id" />
            </div>
          </li>
        </transition-group>
        <div class="list-loading" :key="4324234324234">
          <span v-if="!isLast && tableLoading"
            ><i class="el-icon-loading"></i>加载中</span
          >
          <span v-if="isLast">已全部加载, 共{{ total }}条数据</span>
        </div>
      </section>
    </section>
    <el-dialog
      :title="isEmprty(currentId) ? '新增跟进记录' : '修改跟进记录'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-dialog
      title="跟进记录详情"
      :visible.sync="detaiDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      @close="currentId = null"
    >
      <Detail :id="currentId" />
    </el-dialog>
    <el-dialog
      title="通知"
      :visible.sync="messageDialog"
      append-to-body
      :close-on-click-modal="false"
      width="90%"
    >
      <Message @close="messageDialog = false" />
    </el-dialog>
    <el-dialog
      title="新增提醒"
      :visible.sync="messageFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <MessageForm
        :id="currentRow.id"
        :name="currentRow.clientName"
        @success="messageFormSuccess"
        @cancel="messageFormCancel"
      />
    </el-dialog>
    <el-dialog
      title="通知提醒"
      :visible.sync="messageFormRemindDialog"
      append-to-body
      :close-on-click-modal="false"
      width="70%"
    >
      <MessageRemind
        @showdateil="handleShowFollowUpDetail"
        @close="messageFormRemindDialog = false"
      />
    </el-dialog>
  </section>
</template>

<script>
import { GetClientFollowUpsRecordList } from "./api";
import { GetClientFollowUpsMessagesList } from "./api/message";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import Form from "./components/Form";
import Detail from "./components/Detail";
import Comments from "./components/Comments";
import comonControl from "./mixins/comonControl";
import FilterSection from "./components/FilterSection";
import PageHeader from "@/components/PageHeader";
import FilesItem from "@/views/filesModule/components/FilesItem";
import Message from "./components/Message";
import MessageForm from "./components/MessageForm";
import MessageRemind from "./components/MessageRemind";
import config from "@/config";
export default {
  components: {
    Message,
    MessageForm,
    MessageRemind,
    Comments,
    Form,
    Detail,
    FilterSection,
    PageHeader,
    FilesItem,
  },
  mixins: [comonControl],
  data() {
    return {
      messageFormRemindDialog: false,
      messageFormDialog: false,
      messageDialog: false,
      detaiDialog: false,
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      multipleFormDialog: false,
      listTarget: null,
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {
        createdAt: null,
        lastModifiedAt: null,
        followUpStatusId: null,
        createdById: null,
        clientId: [],
      },
      isLast: false,
      scrollPosition: null,
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: null,
      currentRow: {},
      multipleSelected: [],
      tagTypes: ["", "info", "warning", "success", "danger"],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.initScrollList();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "FollowUpsRecordList") {
          this.checkMessageRemindStatus();
          if (this.scrollPosition) {
            this.$nextTick(() => {
              let target =
                document.querySelector("#listScrollWrapper") || this.listTarget;
              target.scrollTop = this.scrollPosition;
            });
          } else {
            this.scrollPosition = null;
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    initScrollList() {
      let _this = this;
      _this.listTarget = document.querySelector("#listScrollWrapper");
      _this.listTarget.addEventListener("scroll", function () {
        _this.scrollPosition = _this.listTarget.scrollTop;
        if (
          _this.listTarget.clientHeight + _this.listTarget.scrollTop >=
          _this.listTarget.scrollHeight
        ) {
          if (!_this.isLast) {
            _this.page = _this.page + 1;
            _this.getTableData();
          }
        }
      });
    },
    getTableData() {
      this.tableLoading = true;
      GetClientFollowUpsRecordList({
        page: this.page,
        size: this.size,
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements, last } = res.data;
          if (content.length > 0) {
            content = content.map((item) => {
              return {
                ...item,
                isEdit: false,
              };
            });
          }
          this.tableData = [...this.tableData, ...content];
          this.total = totalElements;
          this.isLast = last;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.tableData = [];
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onClearAndRefresh() {
      this.orderProp = "createdAt";
      this.orderDirection = null;
      this.onResetFilter();
      this.onRefresh();
    },
    onResetFilter() {
      for (const key in this.tableFilters) {
        if (typeof this.tableFilters[key] === "object") {
          if (this.tableFilters[key] instanceof Array) {
            this.tableFilters[key] = [];
          } else {
            this.tableFilters[key] = null;
          }
        } else {
          this.tableFilters[key] = "";
        }
      }
    },

    onShowClientDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientId,
          tabId: 1,
        },
      });
    },
    addRow() {
      this.formDialog = true;
    },

    onFormCancel() {
      this.currentId = "";
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    onFormSuccess() {
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    handleStart(row, index) {
      if (
        !checkPermission([
          "CLIENT_ADMIN",
          "CLIENT_GROUP_ADMIN",
          "CLIENT_USER",
          "CLIENT_OBSERVER",
        ])
      ) {
        this.$message.error("您无权限设置星标操作");
        return false;
      }
      this.onToggleStart(row.id, true).then((res) => {
        this.$set(this.tableData, index, res.data);
      });
    },
    handleUnStart(row, index) {
      if (
        !checkPermission([
          "CLIENT_ADMIN",
          "CLIENT_GROUP_ADMIN",
          "CLIENT_USER",
          "CLIENT_OBSERVER",
        ])
      ) {
        this.$message.error("您无权限设置星标操作");
        return false;
      }
      this.onToggleStart(row.id, false).then((res) => {
        this.$set(this.tableData, index, res.data);
      });
    },
    handleShowMessage() {
      this.messageDialog = true;
    },
    handleShowFollowUpDetail(id) {
      this.currentId = id;
      this.detaiDialog = true;
    },
    handleMessageAdd(row) {
      this.currentRow = row;
      this.messageFormDialog = true;
    },
    messageFormSuccess() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    messageFormCancel() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    checkMessageRemindStatus() {
      GetClientFollowUpsMessagesList({
        page: 0,
        size: 2000,
        isRead: false,
        needRemind: true,
      }).then((res) => {
        if (res.data.content.length > 0) {
          this.messageFormRemindDialog = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
.base-page {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  .base-page-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .title-nav-hover {
    &:hover {
      color: $theme_color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.main-list-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 43px;
  .list-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-right: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    .work-log-list {
      box-sizing: border-box;
      width: 100%;
      margin-top: 10px;
      list-style-type: none;
      .list-item {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;
        transition: all 1s;
        border-radius: 10px;
        overflow: hidden;
        ::v-deep{
          .el-card{
            border-radius: 0;
          }
        }
        .creater-name {
          font-size: 20px;
          color: #000;
          font-weight: bolder;
        }
        .log-type {
          font-size: 16px;
          padding-left: 10px;
          font-weight: bolder;
          color: #5074dd;
        }
        .log-push-dete {
          font-size: 14px;
          color: #8f8f8f;
          padding-right: 5px;
          margin-top: 10px;
          text-indent: 2em;
        }
      }
      .log-content-wrap {
        box-sizing: border-box;
        .flex-box {
          box-sizing: border-box;
          display: flex;
          border: 1px solid #f1f1f1;
          .left {
            box-sizing: border-box;
            flex: 1;
            padding: 15px;
          }
          .right {
            box-sizing: border-box;
            flex: 400px 0 0;
            border-left: 1px solid #f1f1f1;
            // display: flex;
            // align-items: center;
            // justify-content: center;
          }
        }
      }
      .log-content-block {
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        .log-content-title {
          box-sizing: border-box;
          width: 100%;
          padding-left: 20px;
          font-size: 14px;
          color: #6b6b6b;
          margin-bottom: 10px;
          position: relative;
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #5074dd;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
          }
        }
        .log-content-text {
          box-sizing: border-box;
          padding-left: 20px;
          min-height: 50px;
          text-align: left;
          font-size: 15px;
          color: #000;
          line-height: 24px;
          padding-top: 10px;
          padding-right: 10px;
          ::v-deep {
            .el-textarea__inner {
              font-family: "Avenir", Helvetica, Arial, sans-serif;
              font-family: "Alibaba-PuHuiTi-Regular";
              border: none;
              background-color: transparent;
              padding: 0;
              font-size: 15px;
              color: #000;
              line-height: 27px;
            }
          }
        }
        .follow-up-content {
          box-sizing: border-box;
          width: 100%;
          position: relative;
          padding-left: 80px;
          .content-lable {
            position: absolute;
            top: 3px;
            left: 0;
            font-size: 14px;
            color: #6b6b6b;
          }
          .log-content-text {
            padding: 0;
          }
        }
      }
      .log-item-footer {
        box-sizing: border-box;
        width: 100%;
        padding-top: 20px;
      }
    }
    .item-comments-wrap {
      box-sizing: border-box;
      width: 100%;
      font-size: 14px;
    }
    .list-loading {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 0 15px 0;
      font-size: 14px;
      text-align: center;
      color: #fff;
    }
  }
}

.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>