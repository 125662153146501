<template>
  <div class="base-comment-list-wrap">
    <div class="list-title">
      <span title="点评"
        ><svg-icon iconClass="message"></svg-icon>（{{
          listData.length
        }}）</span
      >
      <span class="text-nav" title="写点评" @click="onShowEditor"
        ><svg-icon iconClass="write"></svg-icon> 写点评</span
      >
    </div>
    <div class="comment-list" v-loading="loading" v-if="listData.length > 0">
      <div
        class="list-item"
        v-for="(item, index) in listData"
        :key="item.id + index"
      >
        <span
          :title="'点击回复：' + item.createdByName"
          @click="handleReply(item)"
          class="list-item-user-name"
          >{{ item.createdByName }}</span
        >
        <span v-if="item.parentId !== null">回复&nbsp;</span>
        <span
          class="list-item-user-name"
          :title="'点击回复：' + item.parentCreatedByName"
          @click="handleReply(item)"
          v-if="item.parentId !== null"
          >{{ item.parentCreatedByName }}</span
        >
        <span class="list-item-content" :title="formatDate(item.createdAt)"
          >: {{ item.content }}</span
        >
        <span class="list-item-date">{{item.createdAt | date_time}}</span>
      </div>
    </div>
    <div class="pagenation" v-if="listData.length > 0">
      <el-pagination
        small
        layout="prev, pager, next"
        :current-page="page + 1"
        :size="size"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div class="no-data" v-if="listData.length <= 0 && !canShowEditor">
      暂无点评,
      <span class="text-nav" @click="onShowEditor"
        ><svg-icon iconClass="write"></svg-icon> 我来写第一个</span
      >
    </div>
    <div class="comment-editor-wrap" v-show="canShowEditor">
      <CommentEditor
        :followUpId="followUpId"
        :parentId="parentId"
        :placeholder="customPlaceholder"
        @cancel="onHiddeEditor"
        @success="onCommentSuccess"
      />
    </div>
  </div>
</template>

<script>
import { GetCommentsList } from "../api/comment";
import CommentEditor from "./CommentEditor";
import { format } from "date-fns";
export default {
  name: "ClientFollowUpsRecordComments",
  components: {
    CommentEditor,
  },
  props: {
    followUpId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      customPlaceholder: "请输入",
      currentId: null,
      canShowEditor: false,
      loading: false,
      parentId: null,
      listData: [],
      page: 0,
      size: 10,
      total: 0,
      totalPages: 0,
    };
  },
  watch: {
    followUpId: {
      handler() {
        if (this.followUpId !== null) {
          this.currentId = this.followUpId;
          this.getCommentListData();
        } else {
          this.currentId = null;
          this.page = 0;
          this.listData = [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatDate(date = "") {
      return "发布于：" + format(new Date(date), "yyyy年MM月dd日 HH:mm:ss");
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getCommentListData();
    },
    getCommentListData() {
      this.loading = true;
      GetCommentsList({
        page: this.page,
        size: this.size,
        "followUp.id": this.currentId,
      })
        .then((res) => {
          let { content, totalElements, totalPages } = res.data;
          this.listData = content;
          this.total = totalElements;
          this.totalPages = totalPages;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onShowEditor() {
      this.customPlaceholder = "请输入点评内容";
      this.parentId = null;
      this.canShowEditor = true;
    },
    onCommentSuccess() {
      this.getCommentListData();
    },
    onHiddeEditor() {
      this.canShowEditor = false;
    },
    handleReply(item) {
      this.parentId = item.id;
      this.customPlaceholder = `回复${item.createdByName}:`;
      this.canShowEditor = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-comment-list-wrap {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  color: rgb(97, 93, 93);
  text-align: center;
  font-size: 14px;
  padding: 10px 20px;
  .comment-list {
    background-color: #f7fbff;
  }
  .list-title {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #f1f1f1;
    padding-bottom: 5px;
  }
  .list-item {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    color: #989898;
    padding: 10px 5px;
    padding-right: 180px;
    font-size: 13px;
    position: relative;
    .list-item-content {
      cursor: pointer;
      color: rgb(66, 65, 65);
    }
    .list-item-date{
      position: absolute;
      top: 10px;
      right: 5px;
      font-size: 13px;
      color: #ccc;
    }
  }
  .list-item-user-name {
    padding: 0 4px;
    color: rgb(95, 95, 95);
    font-size: 14px;
    &:hover {
      color: #03a1ea;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .pagenation {
    box-sizing: border-box;
    text-align: left;
    padding: 10px 0 0;
  }
  .no-data {
    text-align: center;
    padding: 10px 0;
    box-sizing: border-box;
    background-color: #f4f9ff;
  }
}
</style>